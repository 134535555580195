<script setup lang="ts">
const { isLogged } = storeToRefs(useUser())
const { $switchRoute } = useI18n()

onBeforeMount(() => {
  if (isLogged.value === true) {
    $switchRoute('/profile/overview')
  }
})
</script>

<template>
  <div pt-40px pb-80px>
    <div v-if="!isLogged" fubex-container flex flex-col md:flex-row md:gap-80px justify-center items-center md:items-initial>
      <div flex flex-col items-center gap-16px>
        <LoginForm :disable-links="true" />
      </div>
      <div h-1px w-200px md:w-1px md:h-auto my-40px bg-neutral-200 />
      <div flex flex-col justify-center items-center gap-40px>
        <div flex flex-col gap-20px>
          <h4 :data-test="getCypressId('forgot-password-header')" heading6>
            {{ $t('auth.forgotPassword') }}
          </h4>
          <WidgetAuthDialogs default-view="forgottenPassword">
            <FButton name="navigation-register" w-full size="lg" variant="secondary" prefix-icon="i-fubex-lock">
              {{ $t('auth.forgotPassword') }}
            </FButton>
          </WidgetAuthDialogs>
        </div>
        <div h-1px w-200px bg-neutral-200 />
        <div flex flex-col gap-20px>
          <h4 :data-test="getCypressId('sign-up-header')" heading6>
            {{ $t('components.form.createNewAccount') }}
          </h4>
          <WidgetAuthDialogs default-view="register">
            <FButton name="navigation-register" w-full size="lg" variant="secondary" prefix-icon="i-fubex-user">
              {{ $t('components.register') }}
            </FButton>
          </WidgetAuthDialogs>
        </div>
      </div>
    </div>
  </div>
</template>
